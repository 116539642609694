var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-notification","hideHeaderClose":"","ok-title":_vm.$t('Save'),"cancel-title":_vm.$t('Abort'),"cancel-variant":"outline-secondary","size":"lg","centered":""},on:{"shown":_vm.afterShowModal,"hidden":_vm.hiddenModal,"ok":function($event){$event.preventDefault();return _vm.saveData.apply(null, arguments)},"cancel":_vm.resetModal}},[_c('b-overlay',{attrs:{"show":_vm.show,"rounded":"sm"}},[_c('b-row',[_c('b-col',{staticClass:"modalTitle",attrs:{"cols":"12"}},[_c('h2',{staticClass:"font-weight-bolder"},[_vm._v(" "+_vm._s(_vm.$t("notifications.Notification"))+" ")])])],1),_c('validation-observer',{ref:"notification"},[_c('b-form',[_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":_vm.$t('notifications.Time') + ' *',"label-for":"date-time-start"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('notifications.Time')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('flat-pickr',{staticClass:"form-control",attrs:{"id":"date-time-start","placeholder":"YYYY-MM-DD HH:MM","config":{
                    enableTime: true,
                    dateFormat: 'Y-m-d H:i',
                    time_24hr: true,
                  }},model:{value:(_vm.notification.time),callback:function ($$v) {_vm.$set(_vm.notification, "time", $$v)},expression:"notification.time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('notifications.Title') + ' *',"label-for":"link"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('notifications.Title')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"link","placeholder":_vm.$t('notifications.Title')},model:{value:(_vm.notification.title),callback:function ($$v) {_vm.$set(_vm.notification, "title", $$v)},expression:"notification.title"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('short_description') + ' *',"label-for":"description"}},[_c('validation-provider',{attrs:{"rules":"required","name":_vm.$t('short_description')},scopedSlots:_vm._u([{key:"default",fn:function(ref){
                  var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"stand_description","placeholder":_vm.$t('add_short_description'),"rows":"3","maxlength":"255"},model:{value:(_vm.notification.description),callback:function ($$v) {_vm.$set(_vm.notification, "description", $$v)},expression:"notification.description"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"md":"6"}},[_c('vfa-picker',{scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('b-form-group',{attrs:{"label":"Icona","label-for":"icon"}},[_c('b-form-input',{attrs:{"id":"icon","placeholder":"Icon","type":"text"},on:{"click":on},model:{value:(_vm.notification.icon),callback:function ($$v) {_vm.$set(_vm.notification, "icon", $$v)},expression:"notification.icon"}})],1)]}}]),model:{value:(_vm.notification.icon),callback:function ($$v) {_vm.$set(_vm.notification, "icon", $$v)},expression:"notification.icon"}})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Link","label-for":"link"}},[_c('b-form-input',{attrs:{"id":"link","placeholder":"url"},model:{value:(_vm.notification.url),callback:function ($$v) {_vm.$set(_vm.notification, "url", $$v)},expression:"notification.url"}})],1)],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }