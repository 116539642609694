<template>
  <b-modal
    id="modal-notification"
    hideHeaderClose
    :ok-title="$t('Save')"
    :cancel-title="$t('Abort')"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    @shown="afterShowModal"
    @hidden="hiddenModal"
    @ok.prevent="saveData"
    @cancel="resetModal"
  >
    <b-overlay :show="show" rounded="sm">
      <b-row>
        <b-col cols="12" class="modalTitle">
          <h2 class="font-weight-bolder">
            {{ $t("notifications.Notification") }}
          </h2>
        </b-col>
      </b-row>
      <validation-observer ref="notification">
        <b-form>
          <b-row>
            <b-col md="6">
              <b-form-group
                :label="$t('notifications.Time') + ' *'"
                :label-for="`date-time-start`"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('notifications.Time')"
                >
                  <flat-pickr
                    :id="`date-time-start`"
                    placeholder="YYYY-MM-DD HH:MM"
                    class="form-control"
                    v-model="notification.time"
                    :config="{
                      enableTime: true,
                      dateFormat: 'Y-m-d H:i',
                      time_24hr: true,
                    }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col cols="12">
              <b-form-group
                :label="$t('notifications.Title') + ' *'"
                label-for="link"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('notifications.Title')"
                >
                  <b-form-input
                    id="link"
                    :placeholder="$t('notifications.Title')"
                    v-model="notification.title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="12">
              <b-form-group
                :label="$t('short_description') + ' *'"
                label-for="description"
              >
                <validation-provider
                  #default="{ errors }"
                  rules="required"
                  :name="$t('short_description')"
                >
                  <b-form-textarea
                    id="stand_description"
                    v-model="notification.description"
                    :placeholder="$t('add_short_description')"
                    rows="3"
                    maxlength="255"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="6">
              <vfa-picker v-model="notification.icon">
                <template v-slot:activator="{ on }">
                  <b-form-group label="Icona" label-for="icon">
                    <b-form-input
                      id="icon"
                      v-model="notification.icon"
                      @click="on"
                      placeholder="Icon"
                      type="text"
                    />
                  </b-form-group>
                </template>
              </vfa-picker>
            </b-col>
            <b-col md="6">
              <b-form-group label="Link" label-for="link">
                <b-form-input
                  id="link"
                  placeholder="url"
                  v-model="notification.url"
                />
              </b-form-group>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>
<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BModal,
  BOverlay,
  BFormTextarea,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import "@core/scss/vue/libs/vue-flatpicker.scss";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver, localize } from "vee-validate";
import { required } from "@validations";

import {
  createNotifications,
  updateNotifications,
  getNotifications,
} from "@api/notifications";

export default {
  props: ["notification_id"],
  name: "notification_modal",
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BModal,
    BOverlay,
    BFormTextarea,
    flatPickr,
  },
  data() {
    return {
      show: true,
      notification: {
        time: null,
        title: "",
        description: "",
        icon: "",
        url: "",
      },
    };
  },
  directives: {
    Ripple,
  },
  methods: {
    afterShowModal() {
      const $this = this;
      localize("it");
      $this.show = true;
      if (this.notification_id != null) {
        getNotifications(this.notification_id)
          .then((data) => {
            $this.notification = data;
            $this.show = false;
          })
          .catch((error) => {
            console.log(error);
            $this.show = false;
          });
      } else {
        $this.show = false;
      }
    },
    saveData() {
      const $this = this;
      this.$refs.notification.validate().then((success) => {
        if (success) {
          $this.save();
        }
      });
      return false;
    },
    save() {
      const $this = this;
      $this.show = true;
      let formData = {
        time: $this.notification.time,
        title: $this.notification.title,
        description: $this.notification.description,
        icon: $this.notification.icon,
        url: $this.notification.url,
      };
      if ($this.notification_id == null) {
        createNotifications(formData)
          .then(() => {
            this.$emit("onSave");
            this.$bvModal.hide("modal-notification");
            $this.show = false;
          })
          .catch((error) => {
            console.log(error);
            $this.show = false;
          });
      } else {
        updateNotifications($this.notification_id, formData)
          .then(() => {
            this.$emit("onSave");
            this.$bvModal.hide("modal-notification");
            $this.show = false;
          })
          .catch((error) => {
            console.log(error);
            $this.show = false;
          });
      }
    },
    resetModal() {
      this.$bvModal.hide("modal-notification");
    },
    hiddenModal() {
      this.notification = {
        time: null,
        title: "",
        description: "",
        icon: "",
        url: "",
      };
    },
  },
};
</script>
